import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Carousel from "react-bootstrap/Carousel"

import portfolioStyles from "./portfolio.module.scss"
class Portfolio extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow1 = this.handleShow1.bind(this)
    this.handleClose1 = this.handleClose1.bind(this)
    this.handleShow2 = this.handleShow2.bind(this)
    this.handleClose2 = this.handleClose2.bind(this)
    this.handleShow3 = this.handleShow3.bind(this)
    this.handleClose3 = this.handleClose3.bind(this)
    this.handleShow4 = this.handleShow4.bind(this)
    this.handleClose4 = this.handleClose4.bind(this)
    this.handleShow5 = this.handleShow5.bind(this)
    this.handleClose5 = this.handleClose5.bind(this)
    this.handleShow6 = this.handleShow6.bind(this)
    this.handleClose6 = this.handleClose6.bind(this)

    this.state = {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    }
  }

  handleClose1() {
    this.setState({ show1: false })
  }
  handleShow1() {
    this.setState({ show1: true })
  }
  handleClose2() {
    this.setState({ show2: false })
  }
  handleShow2() {
    this.setState({ show2: true })
  }
  handleClose3() {
    this.setState({ show3: false })
  }
  handleShow3() {
    this.setState({ show3: true })
  }
  handleClose4() {
    this.setState({ show4: false })
  }
  handleShow4() {
    this.setState({ show4: true })
  }
  handleClose5() {
    this.setState({ show5: false })
  }
  handleShow5() {
    this.setState({ show5: true })
  }
  handleClose6() {
    this.setState({ show6: false })
  }
  handleShow6() {
    this.setState({ show6: true })
  }

  render() {
    return (
      <div
        id="portfolio"
        style={{ backgroundColor: "#2d2d2d", color: "white" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            // display: "block",
            marginTop: "-126px",
            width: "100%",
            height: "100px",
            color: "#2d2d2d",
            //  transform: "rotate(180deg)",
          }}
          preserveAspectRatio="none"
          viewBox="0 0 200 100"
        >
          <path fill="currentColor" d="M0,0L200,100L0,100z" />
        </svg>
        <Container>
          <div className="sectionHeader">
            <Row noGutters>
              <Col>
                <h2> Portfolio </h2>
              </Col>
            </Row>
          </div>
          <div className="sectionSubHeader">
            <Row noGutters>
              <Col>
                <h3>Take a look at some of our past events</h3>
              </Col>
            </Row>
          </div>

          <div className="sectionContent">
            <Row>
              <Col lg={6} md={12}>
                <div className={portfolioStyles.portfolioImageContainer}>
                  <img
                    className={portfolioStyles.portfolioImage}
                    src="/img/annualDinners.jpg"
                    alt=""
                  />
                  <div className={portfolioStyles.portfolioOverlay}>
                    <h3>
                      Annual Dinners & <br />
                      Anniversary Celebrations
                    </h3>
                    <Button
                      variant="outline-light"
                      onClick={this.handleShow1}
                    >
                      {" "}
                      More Photos{" "}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className={portfolioStyles.portfolioImageContainer}>
                  <img
                    className={portfolioStyles.portfolioImage}
                    src="/img/specialEvents.jpg"
                    alt=""
                  />
                  <div className={portfolioStyles.portfolioOverlay}>
                    <h3>Special Events</h3>
                    <Button
                      variant="outline-light"
                      onClick={this.handleShow2}
                    >
                      {" "}
                      More Photos{" "}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12}>
                <div className={portfolioStyles.portfolioImageContainer}>
                  <img
                    className={portfolioStyles.portfolioImage}
                    src="/img/inaugurations.jpg"
                    alt=""
                  />
                  <div className={portfolioStyles.portfolioOverlay}>
                    <h3>Inauguration Ceremonies</h3>
                    <Button
                      variant="outline-light"
                      onClick={this.handleShow3}
                    >
                      {" "}
                      More Photos{" "}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className={portfolioStyles.portfolioImageContainer}>
                  <img
                    className={portfolioStyles.portfolioImage}
                    src="/img/MICE.jpg"
                    alt=""
                  />
                  <div className={portfolioStyles.portfolioOverlay}>
                    <h3>
                      Meetings, Incentives, <br />
                      Conventions & Exhibitions
                    </h3>
                    <Button
                      variant="outline-light"
                      onClick={this.handleShow4}
                    >
                      {" "}
                      More Photos{" "}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12}>
                <div className={portfolioStyles.portfolioImageContainer}>
                  <img
                    className={portfolioStyles.portfolioImage}
                    src="/img/pressEvents.jpg"
                    alt=""
                  />
                  <div className={portfolioStyles.portfolioOverlay}>
                    <h3>Press Events</h3>
                    <Button
                      variant="outline-light"
                      onClick={this.handleShow5}
                    >
                      {" "}
                      More Photos{" "}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className={portfolioStyles.portfolioImageContainer}>
                  <img
                    className={portfolioStyles.portfolioImage}
                    src="/img/sports.jpg"
                    alt=""
                  />
                  <div className={portfolioStyles.portfolioOverlay}>
                    <h3>Sports & Carnivals</h3>
                    <Button
                      variant="outline-light"
                      onClick={this.handleShow6}
                    >
                      {" "}
                      More Photos{" "}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Modal 1 */}
            <Modal
              show={this.state.show1}
              onHide={this.handleClose1}
              size="xl"
              centered
            >
              <Modal.Body>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/1.jpg"
                      alt=""
                    />
                    {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>
                                  Nulla vitae elit libero, a pharetra augue
                                  mollis interdum.
                                </p>
                              </Carousel.Caption> */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/2.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/3.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/4.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/5.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/6.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/7.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/8.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/9.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/10.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/11.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/12.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/1. Annual Dinners & Anniversary Celebrations/13.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                </Carousel>
              </Modal.Body>
            </Modal>
            {/* Modal 2 */}
            <Modal
              show={this.state.show2}
              onHide={this.handleClose2}
              size="xl"
              centered
            >
              <Modal.Body>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/1.jpg"
                      alt=""
                    />
                    {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>
                                  Nulla vitae elit libero, a pharetra augue
                                  mollis interdum.
                                </p>
                              </Carousel.Caption> */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/2.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/3.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/4.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/5.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/6.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/7.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/8.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/9.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/10.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/11.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/12.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/13.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/14.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/15.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/16.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/2. Special Events/17.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                </Carousel>
              </Modal.Body>
            </Modal>
            {/* Modal 3 */}
            <Modal
              show={this.state.show3}
              onHide={this.handleClose3}
              size="xl"
              centered
            >
              <Modal.Body>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/1.jpg"
                      alt=""
                    />
                    {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>
                                  Nulla vitae elit libero, a pharetra augue
                                  mollis interdum.
                                </p>
                              </Carousel.Caption> */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/2.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/3.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/4.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/5.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/6.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/7.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/3. Inauguration Ceremonies/8.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                </Carousel>
              </Modal.Body>
            </Modal>
            {/* Modal 4 */}
            <Modal
              show={this.state.show4}
              onHide={this.handleClose4}
              size="xl"
              centered
            >
              <Modal.Body>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/2.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/3.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/4.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/5.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/10.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/11.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/12.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/13.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/15.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/16.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/17.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/18.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/19.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/20.jpg"
                      alt=""
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/21.jpg"
                      alt=""
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/22.jpg"
                      alt=""
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/23.jpg"
                      alt=""
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/24.jpg"
                      alt=""
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/4. Meetings, Incentives, Conventions & Exhibitions/25.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                </Carousel>
              </Modal.Body>
            </Modal>
            {/* Modal 5 */}
            <Modal
              show={this.state.show5}
              onHide={this.handleClose5}
              size="xl"
              centered
            >
              <Modal.Body>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/5. Press Events/1.jpg"
                      alt=""
                    />
                    {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>
                                  Nulla vitae elit libero, a pharetra augue
                                  mollis interdum.
                                </p>
                              </Carousel.Caption> */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/5. Press Events/2.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/5. Press Events/3.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/5. Press Events/4.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/5. Press Events/5.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/5. Press Events/6.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                </Carousel>
              </Modal.Body>
            </Modal>
            {/* Modal 6 */}
            <Modal
              show={this.state.show6}
              onHide={this.handleClose6}
              size="xl"
              centered
            >
              <Modal.Body>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/6. Sports & Carnivals/1.jpg"
                      alt=""
                    />
                    {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>
                                  Nulla vitae elit libero, a pharetra augue
                                  mollis interdum.
                                </p>
                              </Carousel.Caption> */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/6. Sports & Carnivals/2.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/6. Sports & Carnivals/4.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/6. Sports & Carnivals/5.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/6. Sports & Carnivals/6.jpg"
                      alt=""
                    />
                  </Carousel.Item>
                </Carousel>
              </Modal.Body>
            </Modal>
          </div>
        </Container>
      </div>
    )
  }
}

export default Portfolio
