import React from "react"

import WhatWeDoModal from "./whatWeDoModal"
import CompanyHistoryModal from "./companyHistoryModal"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import whatWeDoStyles from "./whatWeDo.module.scss"

const WhatWeDo = () => {
  return (
    <div id="aboutUs" className={whatWeDoStyles.background}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          // display: "block",
          marginTop: "0px",
          width: "100%",
          height: "100px",
          color: "white",
          transform: "rotate(180deg)",
        }}
        preserveAspectRatio="none"
        viewBox="0 0 200 100"
      >
        <path fill="currentColor" d="M0,0L200,100L0,100z" />
      </svg>
      <Container>
        <div className="sectionHeader">
          <Row noGutters>
            <Col>
              <h2>About Us</h2>
            </Col>
          </Row>
        </div>
        <div className="sectionSubHeader">
          <Row noGutters>
            <Col>
              <h3>There's no simpler way to show what we do best</h3>
            </Col>
          </Row>
        </div>
        <div className="sectionContent">
          <Row noGutters>
            <Col>
              <CompanyHistoryModal />
            </Col>
            <Col>
              <WhatWeDoModal />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default WhatWeDo
