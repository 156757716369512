import React from "react"

// import smooth scrolling
import Scrollchor from "react-scrollchor"

// import bootstrap components
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import navbarStyles from "./navbar.module.scss"

export default class Navigation extends React.Component {
  state = {
    color: "rgba(255,255,255,0.8)",
    fontColor: "rgb (45,45,45)",
    expanded: false,
  }

  listenScrollEvent = e => {
    if ((window.scrollY || window.pageYOffset) > 100) {
      this.setState({ color: "white", fontColor: "rgb(45,45,45)" })
    } else {
      this.setState({
        color: "rgba(255,255,255,0.8)",
        fontColor: "rgb(45,45,45)",
      })
    }
  }

  setExpanded = e => {
    this.setState({ expanded: e })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent)
  }

  render() {
    return (
      <div>
        <Navbar
          fixed="top"
          style={{ backgroundColor: this.state.color }}
          expand="lg"
          expanded={this.state.expanded}
          onToggle={v => this.setExpanded(v)}
        >
          <Navbar.Brand>
            <Scrollchor to="#" className="nav-link navbar-dark">
              <img
                src="/img/MillionProductionLogo.svg"
                alt="Million Production & Promotion Co."
                className={navbarStyles.logo}
              />
            </Scrollchor>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Scrollchor
                to="#aboutUs"
                animate={{ offset: -100 }}
                className="nav-link"
                style={{ color: this.state.fontColor }}
                beforeAnimate={() => this.setExpanded(false)}
              >
                About Us
              </Scrollchor>
              <Scrollchor
                to="#services"
                animate={{ offset: -100 }}
                className="nav-link"
                style={{ color: this.state.fontColor }}
                beforeAnimate={() => this.setExpanded(false)}
              >
                Services
              </Scrollchor>
              <Scrollchor
                to="#clients"
                animate={{ offset: -100 }}
                className="nav-link"
                style={{ color: this.state.fontColor }}
                beforeAnimate={() => this.setExpanded(false)}
              >
                Clients
              </Scrollchor>
              <Scrollchor
                to="#testimonials"
                animate={{ offset: -100 }}
                className="nav-link"
                style={{ color: this.state.fontColor }}
                beforeAnimate={() => this.setExpanded(false)}
              >
                Testimonials
              </Scrollchor>
              <Scrollchor
                to="#portfolio"
                animate={{ offset: -100 }}
                className="nav-link"
                style={{ color: this.state.fontColor }}
                beforeAnimate={() => this.setExpanded(false)}
              >
                Portfolio
              </Scrollchor>
              <Scrollchor
                to="#contactUs"
                animate={{ offset: 0 }}
                className="nav-link"
                style={{ color: this.state.fontColor }}
                beforeAnimate={() => this.setExpanded(false)}
              >
                Contact Us
              </Scrollchor>

              <Nav.Link
                href="/tc"
                style={{ color: this.state.fontColor}}
              >
                繁
              </Nav.Link>

              <Nav.Link
                href="/sc"
                style={{ color: this.state.fontColor}}                
              >
                簡
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}
