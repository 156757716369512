import React from "react"

import wordFlipStyles from "./wordFlip.module.scss"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Company = () => {
  return (
    <div id="company">
      <Container>
        <div className="sectionHeader">
          <Row noGutters>
            <Col>
              <img
                src="/img/MillionProductionLogo.svg"
                alt="Million Production & Promotion Co."
                style={{ maxWidth: "80vw", paddingBottom: "20px" }}
              />
            </Col>
          </Row>
        </div>
        <div className="sectionSubHeader">
          <Row noGutters>
            <Col>
              <div>
                <h3>
                  Million Production and Promotion Co. <br />
                  provides a one-stop source for event production and management
                  services
                </h3>
              </div>
            </Col>
          </Row>
        </div>
        <div className="sectionContent">
          <Row noGutters>
            <Col>
              <div>
                <h2 className={wordFlipStyles.sentence}>
                  Right &nbsp;
                  <div className={wordFlipStyles.verticalFlip}>
                    <span>Time</span>
                    <span>Audience</span>
                    <span>Message</span>
                    <span>Now</span>
                  </div>
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Company
