import React from "react"

import NavBar from "../components/navbar"
import Policy from "../components/policy"
import Footer from "../components/footer"

import "../styles/index.scss"
import layoutStyles from "./layout.module.scss"

const Layout = props => {
  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <NavBar />
        {props.children}
      </div>
      <Policy />
      <Footer />
    </div>
  )
}

export default Layout
