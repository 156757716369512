import React from "react"

import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import Modal from "react-bootstrap/Modal"

// import { library } from "@fortawesome/fontawesome-svg-core"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faEdit } from "@fortawesome/free-solid-svg-icons"
// library.add(faEdit)

class MyModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Overview</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // paddingLeft: "0px",
          }}
        >
          {/* <Col xs={0} sm={0} lg={4}>
            <img
              className="historyPic"
              style={{ height: "auto", width: "100%" }}
              src="/img/download.png"
              alt=""
            />
          </Col>
          <Col xs={12} sm={12} lg={8}> */}
          <Col>
            <br />
            <p style={{ fontSize: "20px" }}>
              Million Production and Promotion Co. was established in 1989 by
              Mr. Francis Mak Yun Sau, BBS, MH, an industry leader in the
              entertainment and promotion industry.
            </p>
            <br />
            <p style={{ fontSize: "20px" }}>
              Throughout the years, Mr. Mak has led his team with his
              comprehensive industry experience and knowledge to design, consult
              and organize numerous successful events for local and
              multinational corporations.
            </p>
            <br />
            <p style={{ fontSize: "20px" }}>
              With Mr. Mak's extensive knowledge in the event management
              industry along with his innovative team, Million Production and
              Promotion Co. will provide you with the best ideas and solutions
              for your event needs.
            </p>
          </Col>
        </Modal.Body>
      </Modal>
    )
  }
}

class CompanyHistoryModal extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = { modalShow: false }
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false })

    return (
      <ButtonToolbar style={{ paddingLeft: "10vw" }}>
        <Button
          onClick={() => this.setState({ modalShow: true })}
          style={{
            backgroundColor: "transparent",
            border: "solid white 1px",
            width: "100px",
            // borderRadius: "50%",
          }}
          // style={{
          //   backgroundColor: "transparent",
          //   border: "none",
          //   borderRadius: "50%",
          // }}
        >
          Overview
          {/* <FontAwesomeIcon
            className="historyIcon"
            icon="edit"
            style={{ color: "white", fontSize: "100px" }}
          /> */}
        </Button>

        <MyModal show={this.state.modalShow} onHide={modalClose} />
      </ButtonToolbar>
    )
  }
}

export default CompanyHistoryModal
