import React from "react"

// import smooth scrolling
import Scrollchor from "react-scrollchor"

import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import heroStyles from "./hero.module.scss"

const Hero = () => {
  return (
    <div className={heroStyles.hero}>
      <Container>
        <div className={heroStyles.heroContent}>
          <h1>
            We Create <br />
            Memorable Events
          </h1>
          <div id="hero-description">
            <h3>
              We help companies and event organizers <br />
              create unique experiences that captivate their <br />
              audiences by creating unforgettable events.
            </h3>
          </div>
          <Scrollchor to="#services" animate={{ offset: -100 }}>
            <Button
              style={{
                boxShadow: "0 0 15px black",
                backgroundColor: "#413C58",
              }}
            >
              How can we help?
            </Button>
          </Scrollchor>
        </div>
        
      </Container>
    </div>
  )
}

export default Hero
