import React from "react"

const serviceAccordion = () => {
  return (
    <div className="serviceAccordion" >
      <ul>
        <li>
          <div>
            <a href="#services">
              <h3>
                Concept <br />
                Design
              </h3>
              <h4>
                <br />
                From annual dinners to product launches, <br />
                we can help you design & create an event <br />
                that will deliver your intended message.
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>
                Stage and <br />
                Venue Decoration
              </h3>
              <h4>
                <br />
                Transform your venue with our <br />
                tailor-made solutions to give your <br />
                guests an unforgettable experience.
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>
                Program <br />
                Logistics
              </h3>
              <h4>
                <br />
                Planning is as important as execution, <br />
                our extensive experience in the industry <br />
                will ensure a well-coordinated event.
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>
                Audio Visual <br />
                Solutions
              </h3>
              <h4>
                <br />
                From lighting, audio, LED to 
                <br />
                stage pyrotechnics, we've got <br />
                your audience covered and captured.
              </h4>
            </a>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default serviceAccordion
