import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Company from "../components/company"
import WhatWeDo from "../components/whatWeDo"
import Service from "../components/service"
import Client from "../components/client"
import Testimonial from "../components/testimonial"
import Portfolio from "../components/portfolio"
import ContactUs from "../components/contactUs"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Million Production & Promotion Co.</title>
        <meta
          property="description"
          content="Million Production & Promotion Co. provides a one-stop source for event production and management services."
        />       
        <meta
          property="og:title"
          content="Million Production & Promotion Co."
        />
         <meta
          property="og:url"
          content="https://www.millionprduction.com.hk/"
        />
        <meta
          property="og:description"
          content="Million Production & Promotion Co. provides a one-stop source for event production and management services."
        />
          <meta
          property="og:image"
          content="https://www.millionproduction.com.hk/img/logo-millionproduction.com.hk.png/"
        />
      </Helmet>
      <Hero />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          // display: "block",
          marginTop: "-126px",
          width: "100%",
          height: "100px",
          color: "white",
          // transform: "rotate(180deg)",
        }}
        preserveAspectRatio="none"
        viewBox="0 0 200 100"
      >
        <path fill="currentColor" d="M0,0L200,100L0,100z" />
      </svg>
      <Company />
      <WhatWeDo />

      <Service />
      <Client />
      <Testimonial />
      <Portfolio />
      <ContactUs />
    </Layout>
  )
}

export default IndexPage
