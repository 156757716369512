import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Service = () => {
  return (
    <div className="serviceBox">
      <Container>
        <Row>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img src=".//img/conceptDesign.jpg" alt="Concept Design" />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                From annual dinners to product launches, we can help you design
                & create an event that will deliver your intended message.
              </p>
            </div>

            <h3>Concept Design</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img
                src="/img/stageVenueDecoration.jpg"
                alt="Stage and Venue Decoration"
              />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                Transform your venue with our tailor-made solutions to give your
                guests an unforgettable experience.
              </p>
            </div>

            <h3>Stage and Venue Decoration</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img src="/img/programLogistics.jpg" alt="Program Logistics" />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                Planning is as important as execution, our extensive experience
                in the industry will ensure a well-coordinated event.
              </p>
            </div>

            <h3>Program Logistics</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img
                src="/img/audiovisualEffects.jpg"
                alt="audiovisual Effect"
              />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                From lighting, sound, projection and music, to laser shows and
                even indoor fireworks, we know how to make your event special.
              </p>
            </div>

            <h3>Audio Visual Solutions</h3>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Service
