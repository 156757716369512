import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import ClientsCarousel from "./clientsCarousel"

const Client = () => {
  return (
    <div id="clients">
     <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            // display: "block",
            marginTop: "-126px",
            width: "100%",
            height: "100px",
            color: "white",
            // transform: "rotate(180deg)",
          }}
          preserveAspectRatio="none"
          viewBox="0 0 200 100"
        >
          <path fill="currentColor" d="M0,0L200,100L0,100z" />
        </svg>
      <Container>
        <div className="sectionHeader">
          <Row noGutters>
            <Col>
              <h2>Clients</h2>
            </Col>
          </Row>
        </div>
        <div className="sectionSubHeader">
          <Row noGutters>
            <Col>
              <h3>
                Some of our clients that depend on us to deliver a memorable
                event
              </h3>
            </Col>
          </Row>
        </div>
        <div className="sectionContent">
          <Row noGutters>
            <Col>
              <ClientsCarousel />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Client
