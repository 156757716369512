import React from "react"

import TestimonialsCards from "./testimonialsCards"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Testimonial = () => {
  return (
    <div id="testimonials" style={{ backgroundColor: "#d5d5d5" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          // display: "block",
          marginTop: "-126px",
          width: "100%",
          height: "100px",
          color: "#d5d5d5",
          // transform: "rotate(180deg)",
        }}
        preserveAspectRatio="none"
        viewBox="0 0 200 100"
      >
        <path fill="currentColor" d="M0,0L200,100L0,100z" />
      </svg>
      <Container>
        <div className="sectionHeader">
          <Row noGutters>
            <Col>
              <h2>Testimonials</h2>
            </Col>
          </Row>
        </div>
        <div className="sectionSubHeader">
          <Row noGutters>
            <Col>
              <h3>See what our client says about us</h3>
            </Col>
          </Row>
        </div>
        <div className="sectionContent">
          <TestimonialsCards />
        </div>
      </Container>
    </div>
  )
}

export default Testimonial
